import { useLayoutEffect, createRef } from 'react';
import './Slide.css';



export default function Slide(props: {
    name: string,
    subtitle: string,
    prompt: string,
    answer: string,
    extra: string
}) {
    let name_area = createRef<HTMLDivElement>();
    const name_holder_area = createRef<HTMLDivElement>();
    let subtitle_area = createRef<HTMLDivElement>(); 
    let prompt_area = createRef<HTMLDivElement>();
    let slide = createRef<HTMLDivElement>();

    useLayoutEffect(() => {
        const name_holder_width = name_holder_area.current!.clientWidth;
        let name_width = name_area.current!.scrollWidth;
        let name_font_size = 99;
        name_area.current!.style.fontSize = name_font_size + "px";
        const minimum_font_size = 20;
        while (name_width > name_holder_width && name_font_size > minimum_font_size) {
            name_font_size -= 1;
            name_area.current!.style.fontSize = name_font_size + "px";
            name_width = name_area.current!.scrollWidth;
        }
        name_area.current!.style.overflow = "hidden";          
    }, [props.name, props.subtitle, props.prompt, props.answer, name_area, name_holder_area]);
    return (
        <div className='slide-holder'>
            <div className="slide" ref={slide}>
                <div className="name-holder" ref={name_holder_area}><div style={{
                    fontSize: "100px"
                }} className="name-area" ref={name_area}>{props.name}</div></div>
                <div className="subtitle-area" ref={subtitle_area}>{props.subtitle}</div>
                <div style={{
                    fontSize: "50px"
                }} className="prompt-area" ref={prompt_area}>{props.prompt}: {props.answer}</div>
                <div style={{
                    fontSize: "50px",
                    fontStyle: "italic"
                }}className="extra-area">{props.extra}</div>
            </div>
        </div>
    )
}