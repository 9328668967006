import React, { useCallback, useEffect, useRef, useState } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import SlideSlide from "./SlideSlide";
import './Control.css';
import { Accordion, AccordionDetails, AccordionSummary, Button, createTheme, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField, ThemeProvider, Typography } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

const generate_random_msg_id = () => {
    let id = "";
    for (let i = 0; i < 32; i++) {
        const r = Math.floor(Math.random() * 16);
        const c = r.toString(16);
        id += c;
    }
    return id;
}


const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

enum TokenState {
    UNCHECKED,
    NO_TOKEN,
    HAVE_TOKEN
}

export default function Control() {
    const [name, setName] = useState("");
    const [subtitle, setSubtitle] = useState("");
    const [prompt, setPrompt] = useState("");
    const [answer, setAnswer] = useState("");
    const [extra, setExtra] = useState("");
    const token = useRef("");
    const [num_of_slides, set_num_of_slides] = useState<number>(0);
    const [this_slide_num, set_this_slide_num] = useState<number>(0);
    const [password, set_password] = useState("");
    const [token_state, set_token_state] = useState<TokenState>(TokenState.UNCHECKED);
    const [sc_active, set_sc_active] = useState(false);
    const [delete_confirm_open, set_delete_confirm_open] = useState(false);
    const [top_text, set_top_text] = useState("Happy Hour until 10:00! 2 cocktails for £10!");
    const [logo, set_logo] = useState("blockbuster");
    const messages_awaiting_response = useRef<{
        [id: string]: (value: any) => void;
    }>({});
    const radio = useRef<HTMLInputElement>(null);

    const { lastJsonMessage, readyState, sendJsonMessage } = useWebSocket('wss://controlserv.calluml.xyz/', {
        reconnectAttempts: 1e6,
        reconnectInterval: (reconnect_number) => {
            reconnect_number--;
            return 500;
        },
        shouldReconnect: () => true,
    });

    const responseTo = useCallback(async function (msg: any): Promise<any> {
        const id = generate_random_msg_id();
        const res = new Promise((resolve, reject) => {
            messages_awaiting_response.current[id] = resolve;
        });
        console.log("Sending", msg, "with id", id, "and token", token.current);
        sendJsonMessage({ ...msg, id, token: token.current });
        await res;
        console.log("Got response", res);
        return res;
    }, [sendJsonMessage]);

    const handleWSMsg = useCallback(() => {
        const msg = lastJsonMessage as any;
        console.log("Received", msg);
        if (msg.id in messages_awaiting_response.current) {
            messages_awaiting_response.current[msg.id](msg);
            delete messages_awaiting_response.current[msg.id];
        } else {
            console.log("Unhandled message", msg);
        }
    }, [lastJsonMessage]);

    const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Connected',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    }[readyState];

    const get_num_slides = async () => {
        const resp = await responseTo({ type: "slide_count_request" });
        set_num_of_slides(resp.count);
    }

    const delete_all_slides = async () => {
        const resp = await responseTo({ type: "slide_delete_all" });
        if (resp.type !== "ok") {
            console.log("Error: expected ok, got", resp);
            return;
        }
        set_num_of_slides(1);
        get_slide();
    }

    const get_slide = async (id?: number) => {
        let resp;
        if (!id) resp = await responseTo({ type: "slide_request" });
        else resp = await responseTo({ type: "slide_request", slide_id: id });
        if (resp.type !== "slide") {
            console.log("Error: expected slide_response, got", resp);
            return;
        }
        const data = await resp.slide as SlideSlide;
        setName(data.name);
        setSubtitle(data.subtitle ?? "");
        setPrompt(data.prompt ?? "");
        setAnswer(data.promptResponse ?? "");
        setExtra(data.extra ?? "");
        set_this_slide_num(resp.slide_id);
    }

    const update_slide = async (id: number, slide: SlideSlide) => {
        const resp = await responseTo({ type: "slide_update", slide_id: id, slide: slide });
        if (resp.type !== "ok") {
            console.log("Error: expected ok, got", resp);
            return;
        }
        set_num_of_slides(resp.num_slides);
    }

    const upd = async () => {
        update_slide (this_slide_num, { name: name, subtitle: subtitle, prompt: prompt, promptResponse: answer, extra: extra });
    };

    const show_slide = async (id: number) => {
        const update_slide_resp = await responseTo({ type: "slide_update", slide_id: id, slide: { name: name, subtitle: subtitle, prompt: prompt, promptResponse: answer, extra } });
        if (update_slide_resp.type === "ok") {
            const resp = await responseTo({ type: "show_slide", slide_id: id });
            if (resp.type !== "ok") {
                console.log("Error: expected ok, got", resp);
                return;
            } else {
                if (sc_active) set_sc_active(false);
            }
        } else {
            console.log("Error: expected ok, got", update_slide_resp);
        }
    }

    const update_top_text = async () => {
        const resp = await responseTo({ type: "update_top_text", text: top_text });
        if (resp.type !== "ok") {
            console.log("Error: expected ok, got", resp);
            return;
        }
    }

    const get_top_text = async () => {
        const resp = await responseTo({ type: "get_top_text" });
        if (resp.type !== "top_text") {
            console.log("Error: expected top_text, got", resp);
            return;
        }
        set_top_text(resp.text);
    }

    const addnewslide = () => {
        setName("");
        setSubtitle("");
        setAnswer("");
        setExtra("");
        set_num_of_slides(num_of_slides + 1);
        set_this_slide_num(num_of_slides);
    }

    const show_screensaver = async () => {
        const resp = await responseTo({ type: "show_screensaver" });
        if (resp.type !== "ok") {
            console.log("Error: expected ok, got", resp);
            return;
        } else {
            set_sc_active(true);
        }
    }

    useEffect(() => {
        if (localStorage.getItem("token")) {
            const tkn = localStorage.getItem("token")!;
            responseTo({ type: "token_check", token_to_check: tkn }).then((resp) => {
                if (resp.type === "token_valid") {
                    token.current = tkn;
                    set_token_state(TokenState.HAVE_TOKEN);
                } else {
                    set_token_state(TokenState.NO_TOKEN);
                }
            }
            );
        } else {
            set_token_state(TokenState.NO_TOKEN);
        }
        if (token_state === TokenState.HAVE_TOKEN) {
            get_num_slides();
            get_slide();
            get_top_text();
            get_logo();
        }
    }, [token_state]);

    const get_logo = async () => {
        const resp = await responseTo({ type: "get_logo" });
        if (resp.type !== "logo") {
            console.log("Error: expected logo, got", resp);
            return;
        }
        set_logo(resp.logo);
        console.log(radio.current!.value);
        radio.current!.value = resp.logo;
        radio.current!.value = "dockyard";
    }

    useEffect(() => {
        console.log("Connection State:", connectionStatus);
    }, [connectionStatus]);

    useEffect(() => {
        if (lastJsonMessage !== null) {
            handleWSMsg();
        }
    }, [lastJsonMessage, handleWSMsg]);

    if (token_state === TokenState.UNCHECKED) {
        return (
            <ThemeProvider theme={darkTheme}>
                <div className="control-area">
                    <div className="top-zone">
                        <p className="connection-status">Connection Status: {connectionStatus}</p>
                    </div>
                    <div className="slide-input-area">
                        <p>Loading...</p>
                    </div>
                </div>
            </ThemeProvider>
        )
    }

    if (token_state === TokenState.NO_TOKEN) {
        return (
            <ThemeProvider theme={darkTheme}>
                <div className="control-area">
                    <div className="top-zone">
                        <p className="connection-status">Connection Status: {connectionStatus}</p>
                    </div>
                    <div className="slide-input-area">
                        <TextField type="password" fullWidth margin="normal" label="Password" onChange={(e) => set_password(e.target.value)} variant="outlined" />
                        <Button variant="contained" color="primary" onClick={() => {
                            responseTo({ type: "token_request", password: password }).then((resp) => {
                                if (resp.type === "token") {
                                    token.current = resp.token;
                                    localStorage.setItem("token", token.current);
                                    set_token_state(TokenState.HAVE_TOKEN);
                                } else {
                                    console.log("Error", resp);
                                }
                            });
                        }}>Login</Button>
                    </div>
                </div>
            </ThemeProvider>
        )
    }

    return (
        <ThemeProvider theme={darkTheme}>
            <div className="control-area">
                <div className="top-zone">
                    <p className="connection-status">Connection Status: {connectionStatus}</p>
                    <p className="slide-number">Slide {this_slide_num + 1} of {num_of_slides}</p>
                </div>
                <div className="slide-input-area">
                    <TextField inputProps={{ autoCapitalize: "words" }} fullWidth margin="normal" label="Name" value={name} onChange={(e) => setName(e.target.value)} onBlur={
                        () => {
                            upd();
                        }
                    } variant="outlined" />
                    <TextField fullWidth margin="normal" label="Subtitle" value={subtitle} onChange={(e) => setSubtitle(e.target.value)} onBlur={
                        () => {
                            upd();
                        }
                    } variant="outlined" />
                    <TextField inputProps={{ autoCapitalize: "words" }} fullWidth margin="normal" label="Prompt" value={prompt} onChange={(e) => setPrompt(e.target.value)} onBlur={
                        () => {
                            upd();
                        }
                    } variant="outlined" />
                    <TextField inputProps={{ autoCapitalize: "words" }} fullWidth margin="normal" label="Answer" value={answer} onChange={(e) => setAnswer(e.target.value)} onBlur={
                        () => {
                            upd();
                        }
                    } variant="outlined" />
                    <TextField inputProps={{ autoCapitalize: "words" }} fullWidth margin="normal" label="Extra" value={extra} onChange={(e) => setExtra(e.target.value)} onBlur={
                        () => {
                            upd();
                        }
                    } variant="outlined" />
                </div>
                <div className="button-zone">
                {   this_slide_num > 0 ?
                    <Button className="btn" size="large" variant="contained" color="primary" onClick={() => {
                        get_slide(this_slide_num - 1);
                    }}>Previous</Button>
                    : <Button className="btn" size="large" disabled variant="contained" color="primary" onClick={() => {}}>Previous</Button>
                }
                <Button className="btn" size="large" variant="contained" color="primary" onClick={() => {
                    show_slide(this_slide_num);
                }}>Show</Button>
                {   this_slide_num < num_of_slides - 1 ?
                    <Button className="btn" size="large" variant="contained" color="primary" onClick={() => {
                        get_slide(this_slide_num + 1);
                    }}>Next</Button>
                    : <Button className="btn" size="large" disabled variant="contained" color="primary" onClick={() => {}}>Next</Button>
                }
                <Button className="btn" size="large" variant="contained" color="primary" onClick={() => {
                    addnewslide();
                }}>Add New Slide</Button>
                <Button disabled={sc_active} className="btn" size="large" variant="contained" color="primary" onClick={() => {
                    show_screensaver();
                }}>Show Screensaver</Button>
                </div>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography>Advanced</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="advanced-area">
                            <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">Logo</FormLabel>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="blockbuster"
                                    name="radio-buttons-group"
                                    onChange={async(e) => {
                                        const val = e.target.value;
                                        const resp = await responseTo({ type: "set_logo", logo: val });
                                        if (resp.type !== "ok") {
                                            console.log("Error: expected ok, got", resp);
                                            return;
                                        } else {
                                            set_logo(val);
                                        }
                                    }}
                                    ref={radio}
                                    value={logo}
                                >
                                    <FormControlLabel value="blockbuster" control={<Radio />} label="Blocky B" />
                                    <FormControlLabel value="dockyard" control={<Radio />} label="Docky Y" />
                                </RadioGroup>
                                </FormControl>
                            <TextField fullWidth margin="normal" label="Screensaver Scroll Text" value={top_text} onChange={(e) => {
                                set_top_text(e.target.value);
                            }} onBlur={
                                        () => {
                                            update_top_text();
                                        }
                                    } variant="outlined" style={{
                                paddingBottom: 55
                             }} />
                            <Button className="btn" size="large" variant="contained" color="warning" onClick={(e) => { set_delete_confirm_open(true); }}>CLEAR ALL SLIDES</Button>
                            <Dialog open={delete_confirm_open} onClose={() => { set_delete_confirm_open(false); }} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                                <DialogTitle id="alert-dialog-title">{"Are you sure you want to clear all slides?"}</DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        This action cannot be undone.
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => { set_delete_confirm_open(false); }} color="primary">
                                        Cancel
                                    </Button>
                                    <Button onClick={() => { set_delete_confirm_open(false); delete_all_slides(); }} color="warning" autoFocus>
                                        Clear All Slides
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
        </ThemeProvider>
    );
}