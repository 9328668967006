import { createRef, useEffect, useRef, useState } from 'react';
import './PowerPointNew.css'
import Slide from './Slide';
import SlideSlide from './SlideSlide';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import Screensaver from './Screensaver';
import { useWakeLock } from 'react-screen-wake-lock';

const logo_urls = {
    "dockyard": "dy.png",
    "blockbuster": "blockbusters_split.png",
}

const logo_styles = {
    "dy.png": {
        height: "100%",
    },
    "blockbusters_split.png": {
        height: "50%",
    }
}

export default function PowerPoint() {
    const [name, setName] = useState("");
    const [subtitle, setSubtitle] = useState("");
    const [prompt, setPrompt] = useState("");
    const [answer, setAnswer] = useState("");
    const [extra, setExtra] = useState("");
    const [logo_url, set_logo_url] = useState("blockbusters_split.png");
    const [sc_active, set_sc_active] = useState(false);
    const lock_released = useRef(false);
    const [top_text , set_top_text] = useState("Happy Hour until 10:00! 2 cocktails for £10!");
    const top_text_elem = createRef<HTMLDivElement>();

    console.log(2);
    
    const { isSupported, released, request, release } = useWakeLock({
        onRequest: () => {
            console.log('Screen Wake Lock: requested!');
            lock_released.current = false;
        },
        onError: (e) => console.log('Wakelock error', e),
        onRelease: () => {
            console.log('Screen Wake Lock: released!');
            lock_released.current = true;
        }
    });

    const { lastJsonMessage, readyState } = useWebSocket('wss://slidesserv.calluml.xyz/', {
        reconnectAttempts: 999,
        reconnectInterval: 3000,
    });

    const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    }[readyState];

    useEffect(() => {
        const set_var_width = () => {
            const root = document.documentElement;
            const ttwidth = document.getElementById('tte')!.scrollWidth;
            root.style.setProperty('--top-text-width', ttwidth + "px");
        };

        if (lastJsonMessage !== null) {
            const data = lastJsonMessage as any;
            console.log(data);
            console.log(connectionStatus);
            if (data.type === "slide") {
                const slide = data.slide as SlideSlide;
                setName(slide.name);
                setSubtitle(slide.subtitle ?? "");
                setPrompt(slide.prompt ?? "");
                setAnswer(slide.promptResponse ?? "");
                setExtra(slide.extra ?? "");
                set_sc_active(false);
            }
            if (data.type === "screensaver") {
                set_sc_active(true);
            }
            if (data.type === "top_text") {
                set_top_text(data.text);
                console.log(top_text_elem);
                setTimeout(set_var_width, 250);
            }
            if (data.type === "logo") {
                set_logo_url((logo_urls as any)[data.logo]);
                console.log((logo_styles as any)[logo_url]);
            }
        }
    }, [lastJsonMessage, connectionStatus]);

    useEffect(() => {
        if (isSupported) {
            console.log("Requesting wakelock");
            request();
        }
        setInterval(() => {
            if (lock_released.current && document.visibilityState === 'visible') {
                console.log("Lost wakelock, requesting another...");
                request();
            }
        }, 1000)
    }, []);

    const get_height = () => {
        console.log(logo_styles, logo_url);
        return (logo_styles as any)[logo_url].height;
    }


    return (
        <>
        <div className="PowerPointNewLeft"></div>
        <div className="TieDieSideBar">
            
        </div>
        </>
    )
}
