import { createRef, useEffect, useRef } from "react";

import './Screensaver.css'
import { act } from "react-dom/test-utils";

const useAnimationFrame = (callback: () => void) => {
    const requestRef = useRef<number>();
    const animate = () => {
        callback();
        requestRef.current = requestAnimationFrame(animate);
    };

    useEffect(() => {
        requestRef.current = requestAnimationFrame(animate);
        return () => cancelAnimationFrame(requestRef.current!);
    }, []);
};

function getNewRandomColor() {
    let ret=`hue-rotate(${Math.random() * 360}deg)`;
    return ret;
}

let x = 0, y = 0, dirX = 1, dirY = 1;
const speed = 2;

export default function BBScreensaver(props: {
    imgsrc?: string,
    width?: number,
    fullscreen?: boolean,
}) {
    const dvd = createRef<HTMLImageElement>();
    const debounce = useRef(false);
    const bounce_ctr = useRef(0);
    const active_bg = useRef(0);
    const bgs = props.imgsrc!;

    useAnimationFrame(() => {
        const dvdref = document.getElementById("dvd")!;
        if (dvdref === null) return;
        let screenHeight, screenWidth;
        if (!props.fullscreen) {
            screenHeight = document.getElementById("black")!.clientHeight;
            screenWidth = document.getElementById("black")!.clientWidth;
        } else {
            screenHeight = window.innerHeight - 5;
            screenWidth = window.innerWidth;
        }
        let dvdWidth = dvdref.clientWidth;
        let dvdHeight = dvdref.clientHeight;
        let has_bounced = false;

        if (y + dvdHeight >= screenHeight || y < 0) {
            dirY *= -1;
            dvdref.style.filter = getNewRandomColor();
            x = Math.max(0, Math.min(x, screenWidth - dvdWidth));
            has_bounced = true;
        }
        if (x + dvdWidth >= screenWidth || x < 0) {
            dirX *= -1;
            dvdref.style.filter = getNewRandomColor();
            y = Math.max(0, Math.min(y, screenHeight - dvdHeight));
            has_bounced = true;
        }

        if (has_bounced && !debounce.current) {
            bounce_ctr.current = bounce_ctr.current + 1;
            if (bounce_ctr.current === 4) {
                bounce_ctr.current = 0;
                const dvdi = dvdref as HTMLImageElement;
                active_bg.current = (active_bg.current + 1) % bgs.length;
                dvdi.src = bgs[active_bg.current];
                dvdWidth = dvdref.clientWidth;
                dvdHeight = dvdref.clientHeight;
            }
            setTimeout(() => {
                debounce.current = false;
            }, 500);
            debounce.current = true;
        }

        x += dirX * speed;
        y += dirY * speed;
        if (x + dvdWidth >= screenWidth) x = screenWidth - dvdWidth;
        if (y + dvdHeight >= screenHeight) y = screenHeight - dvdHeight;
        dvdref.style.left = x + "px";
        dvdref.style.top = y + "px";
    });

    useEffect(() => {
        document.getElementById('dvd')!.style.filter = "#ff8800";
    }, []);


    return (
    <div id={props.fullscreen ? "black-full" : "black"}>
        <img src={"blockbusters_full.png"} id="dvd" alt="" ref={dvd} style={{
            width: props.width ?? 200
        }}  />
    </div>
    )

}